<template>
  <div
    :class="{
      '__active':isCheck,
      '__disabled': disable,
      'bigBtn': filterTemplate === 'button_select'
    }"
    class="tm-buttonCheckboxItem__root"
    @click="onEmit"
  >
    <span>{{ name }}</span>
  </div>
</template>

<script setup lang="ts">
const { name = '', slug = '', value = {}, disable = false, filterTemplate } = defineProps<{
	name: string;
	slug: string;
	value: Record<string, string> | undefined;
	disable: boolean;
  filterTemplate: string
}>();

const emit = defineEmits<{
	check: [slug: string]
}>();

const isCheck = computed(() => Boolean(value?.[slug]));

const onEmit = () => {
  emit('check', slug);
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.tm-buttonCheckboxItem__root {
	color: var(--primary-40);
	min-width: 43px;
	height: 35px;
	padding: 0 12px;
	background-color: #FFF;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 110%; /* 17.6px */
	letter-spacing: -0.64px;
	border: var(--input-border);
	border-radius: var(--input-border-radius);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	&.__active {
		color: var(--primary);
		background: var(--secondary);
	}

	&.__disabled {
		user-select: none;
		pointer-events: none;
		background: #e5e5e5;
		color: #a6a6a6;
	}

	&:hover {
		border: 1px solid var(--dark-primary);
	}
}

@include media('md') {
	.tm-buttonCheckboxItem__root {
		min-width: 50px;
	}
}

.bigBtn {
  height: 49px;

  &:first-child {
    flex: unset !important;
  }
}
</style>
