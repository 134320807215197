<template>
  <div>
    <label class="select-filter__title">{{ labelName }}</label>
    <multiselect
      v-model="selectedVariants"
      tag-placeholder="Add this as new tag"
      placeholder=""
      select-label="Выбрать"
      deselect-label="Удалить"
      selected-label="Выбрано"
      :show-labels="false"
      :close-on-select="false"
      label="label"
      track-by="label"
      :options="filterVariants"
      :multiple="true"
      :taggable="true"
      :disabled="isDisabled"
      :searchable="false"
      @remove="setFilter"
      @close="setFilter"
    >
      <template #caret="{ toggle }">
        <span
          class="multiselect__arrow-icon"
          @mousedown.prevent.stop="toggle"
        >
          <img
            src="~/assets/img/svg/common/arrow-up-yellow.svg"
            alt="arrow"
          >
        </span>
      </template>

      <template #selection="{ values}">
        <span class="multiselect__single">{{ values.length ? `Выбрано: ${values.length}` : labelName }}
        </span>
      </template>
      <template #option="props">
        <li
          class="tm-select__list-item"
        >
          <input
            class="checkbox checkbox-primary"
            type="checkbox"
            :checked="isSelected(props.option.slug)"
          />
          {{ props.option.label }}
        </li>
      </template>
    </multiselect>
  </div>
</template>

<script setup lang="ts">
import Multiselect from 'vue-multiselect';
import type { IFilter } from '#sitis/internal/controllers/filters/models/Filter';
import { ref, computed } from '#imports';

const emit = defineEmits<{
	onChangeFilterVariant: [
		slug: string,
		value: Record<string, string> | null,
	];
}>();

const { filter, selectedFilters, isDisabled = false } = defineProps<{
	filter: IFilter;
	selectedFilters: Record<string, any>;
	isDisabled?: boolean;
}>();

const selectedVariants = ref<{ slug?: string }[]>([]);

const placeholder = computed(() => `${filter.name}`);

const setFilter = () => {
  const variantList: Record<string, string> = {};

  selectedVariants.value.forEach((t: { slug?: string }) => {
    if (t.slug) {
      variantList[t.slug] = t.slug;
    }
  });

  emit('onChangeFilterVariant', filter.slug, variantList);
};

const filterVariants = computed(() => {
  if (filter.id === 'categories') {
    return filter.variants.filter((t) => t.depth === 1);
  }

  return filter.variants;
});

const updateFilter = () => {
  if (selectedFilters && Object.keys(selectedFilters).length) {
    Object.keys(selectedFilters?.[filter.slug] || {}).forEach((t) => {
      if ((selectedVariants.value || []).find((val) => val.slug === t)) {
        return null;
      }

      selectedVariants.value.push({ ...filter.variants?.find((variant) => variant?.slug === t) });
    });
  } else {
    selectedVariants.value = [];
  }
};

const labelName = computed(() => (filter.name === 'Категория' ? 'Проект' : filter.name));

watch(() => selectedFilters, (newValue) => {
  if (JSON.stringify(selectedFilters) === '{}') {
    updateFilter();
  }
  if (isDisabled) {
    selectedVariants.value = [];
  }
});

const isSelected = (slug: string) => Boolean(
  (selectedVariants.value || []).find(
    (t) => t.slug === slug
  )
);

onMounted(() => {
  nextTick(() => {
    updateFilter();
  });
});

</script>

<style lang="scss">
@import "@/assets/scss/media";

.multiselect__select {
  &::before {
    border-color: var(--secondary) transparent transparent transparent;
  }
}

</style>
