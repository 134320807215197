<template>
  <div
    class="tm-buttonCheckboxFilter__root"
    :class="{'peculiarities-root': filter.slug === 'features'}"
  >
    <label class="select-filter__title">{{ filter.name }}</label>
    <div class="tm-buttonCheckboxFilter__list">
      <ButtonCheckboxItem
        v-for="(item,idx) in filter.variants"
        :key="`buttonsList-${idx}-${Object.keys(selectedFilters[filter.slug] || {}).join('__')}`"
        :name="item.label"
        :slug="item.slug"
        :value="selectedFilters[filter.slug]"
        :disable="isDisabledBtn(item.value) || isDisabled"
        :filter-template="filter.filterTemplate"
        @check="changeFilter"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import ButtonCheckboxItem from '~/components/filters/FilterButtonCheckboxList/ButtonCheckboxItem.vue';
import type { IFilter } from '#sitis/internal/controllers/filters/models/Filter';

const { filter, selectedFilters, isDisabled = false } = defineProps<{
	filter: IFilter;
	selectedFilters: Record<string, any>;
	isDisabled?:boolean;
}>();

const emit = defineEmits<{
	onChangeFilterVariant: [
		slug: string,
		value: Record<string, string> | null,
		isDisabled?: boolean,
	];
}>();
const slugList = ref<Record<string, string> | null>(null);

const isDisabledBtn = (value: number) => {
  const available = filter?.available || [];

  return !available.includes(value);
};

const changeFilter = (slug: string) => {
  const newSlug: Record<string, string> = { ...slugList.value };
  const indexValue = Object.keys(newSlug).indexOf(slug);

  if (indexValue > -1) {
    delete newSlug[slug];
  } else {
    newSlug[slug] = slug;
  }

  const isEmpty = Object.keys(newSlug).length === 0;
  slugList.value = isEmpty ? null : newSlug;

  emit('onChangeFilterVariant', filter.slug, slugList.value);
};

onMounted(() => {
  nextTick(() => {
    slugList.value = selectedFilters[filter.slug];
  });
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.tm-buttonCheckboxFilter__root {
	width: fit-content;
}

.peculiarities-root {
  max-width: 400px !important;
}

.tm-buttonCheckboxFilter__list {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	gap: 4px;

	& > div:first-child {
		//flex: 1;
	}
}

@include media('md') {
	.tm-buttonCheckboxFilter__list {
		border: 1px solid transparent;
	}
}
</style>
