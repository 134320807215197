<template>
  <div class="v-range v-range-filter">
    <div class="select-filter__title">{{ filter.name }}</div>

    <div
      :class="{'--disabled':isDisabled}"
      class="v-range_input-block"
    >
      <label>
        <span>от</span>
        <input
          ref="refInputMin"
          v-model="formInputValue.valueMin"
          v-maska:[optionsInputMin]
          data-maska="9 99#"
          data-maska-tokens="9:[-0-9]:repeated"
          data-maska-reversed
          class="input-filter __left"

          @blur="onChangeFilter"
        />
      </label>
      <div class="divide" />
      <label>
        <span>до</span>
        <input
          ref="refInputMax"
          v-model="formInputValue.valueMax"
          v-maska:[optionsInputMax]
          data-maska="9 99#"
          data-maska-tokens="9:[0-9]:repeated"
          data-maska-reversed
          class="input-filter __right"

          @blur="onChangeFilter"
        />
      </label>
    </div>
    <Slider
      v-model="rangeValue"
      :min="rangeMinMax.valueMin"
      :max="rangeMinMax.valueMax"
      :tooltips="true"
      show-tooltip="drag"
      tooltip-position="bottom"
      :disabled="isDisabled"
      :format="(value: number) => priceFormat({amount: value})"
      @end="changeRange"
    />
    <div class="v-range_bottom">
      <p class="v-range_count __min">
        {{ $priceFormat({
          amount: rangeMinMax.valueMin
        }) }}
      </p>
      <p class="v-range_count __max">
        {{ $priceFormat({
          amount: rangeMinMax.valueMax
        }) }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import Slider from '@vueform/slider';
import type { IFilter } from '#sitis/internal/controllers/filters/models/Filter';
import { ref, useNuxtApp } from '#imports';
import { priceFormat } from '#sitis/plugins/priceFormat';

const { $priceFormat } = useNuxtApp();

type FormInputValueType = {
	valueMin: string;
	valueMax: string;
}

type FormRangeValueType = {
	valueMin: number;
	valueMax: number;
}

const emit = defineEmits<{
	onChangeFilterVariant: [slug: string, filterValue: Record<string, string>]
}>();

const { filter, selectedFilters, isDisabled = false } = defineProps<{
	filter: IFilter;
	selectedFilters: Record<string, any>;
	isDisabled?: boolean;
}>();

const refInputMin = ref<HTMLInputElement | null>(null);
const optionsInputMin = {
  preProcess: (val: number | string) => val,

  postProcess: (val: number | string) => {
    if (!val) {
      return '';
    }
    return val;
  }
};

const refInputMax = ref<HTMLInputElement | null>(null);
const optionsInputMax = {
  preProcess: (val: number | string) => val,
  postProcess: (val: number | string) => {
    if (!val) {
      return '';
    }

    return val;
  }
};

const rangeValue = ref<number[]>([20, 40]);
const rangeMinMax = ref<FormRangeValueType>({
  valueMin: 0,
  valueMax: 50
});

const timeOutSubmitFilter = ref<ReturnType<typeof setTimeout>>();
const formInputValue = ref<FormInputValueType>({
  valueMin: '',
  valueMax: ''
});

const initFilter = () => {
  const availableVariants = (filter?.variants || [])
    .filter((t) => Boolean(t.label));

  if (availableVariants.length <= 0) {
    // isInit = false;
    formInputValue.value.valueMin = '';
    formInputValue.value.valueMax = '';

    return;
  }

  const minVariant = Math.floor(Math.min(...availableVariants.map((t) => Number.parseFloat(t.label))));
  const maxVariant = Math.ceil(Math.max(...availableVariants.map((t) => Number.parseFloat(t.label))));

  rangeMinMax.value.valueMin = minVariant;
  rangeMinMax.value.valueMax = maxVariant;

  const selectedValues = selectedFilters?.[filter.slug];
  const selectedValue = selectedValues ? Object.keys(selectedValues)?.[0] : [minVariant, maxVariant].join(':');

  const [min, max] = selectedValue.split(':').map((t) => Number.parseFloat(t));

  formInputValue.value.valueMin = String(min);
  formInputValue.value.valueMax = String(max);

  rangeValue.value = [min, max];
};

const onChangeFilter = () => {
  timeOutSubmitFilter.value = setTimeout(() => {
    if (!formInputValue.value.valueMin) {
      formInputValue.value.valueMin = rangeMinMax.value.valueMin.toString();
    }

    if (!formInputValue.value.valueMax) {
      formInputValue.value.valueMax = rangeMinMax.value.valueMax.toString();
    }

    const minInput = +formInputValue.value.valueMin.replace(/\s/g, '');
    const maxInput = +formInputValue.value.valueMax.replace(/\s/g, '');

    rangeValue.value = [minInput, maxInput];

    emit('onChangeFilterVariant', filter.slug, {
      [rangeValue.value.join(':')]: rangeValue.value.join(':')
    });
  }, 500);
};

const changeRange = (prop: number[]) => {
  formInputValue.value.valueMin = String(prop[0]);
  formInputValue.value.valueMax = String(prop[1]);

  onChangeFilter();
};

watch(() => selectedFilters, () => {
  if (JSON.stringify(selectedFilters) === '{}') {
    initFilter();
  }
});

watch(() => filter, () => {
  initFilter();
});

onMounted(() => {
  nextTick(() => {
    initFilter();
  });
});
</script>
