<template>
  <div class="filter-loader">
    <div class="filter-loader__top">
      <div
        v-for="item in [0,1,2,3,4,5,6,7]"
        :key="`loader-top-filter-${item}`"
        class="loading-indicator"
      />
    </div>
    <div class="filter-loader__bottom">
      <div class="left">
        <div
          v-for="item in [0,1,2,3,4,5,6,7,8,9,10,11,12,13]"
          :key="`loader-bottom-filter-${item}`"
          class="loading-indicator"
        />
      </div>

      <div class="right loading-indicator" />
    </div>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.filter-loader{
	height: 416px;
	border-radius: 12px;
	border: 0.75px solid #ECE8F0;
	background: var(--primary-10);
	padding: 32px;
	display: flex;
	flex-direction: column;
}

.filter-loader__top{
	display: flex;
	flex-wrap: wrap;
	margin-left: -25px;
	margin-top: -15px;
	margin-bottom: 25px;

	& > div{
		width: calc(100% / 4 - 25px);
		margin-left: 25px;
		margin-top: 25px;
		height: 48px;
		border-radius: var(--input-border-radius);
		border: 0.75px solid var(--primary-15);
		box-shadow: 0 2px 4px 0 #cfc7e7;
	}
}

.filter-loader__bottom{
	margin-top: auto;
	display: flex;
	gap: 20px;

	& > .left{
		display: flex;
		flex-wrap: wrap;
		gap: 10px;

		& > div{
			width: 135px;
			height: 35px;
			border-radius: 8px;
			box-shadow: 0 2px 4px 0 #cfc7e7;
			border: var(--input-border);
		}
	}

	& > .right{
		border: var(--input-border);
		height: 48px;
		border-radius: 50px;
		min-width: 245px;
		box-shadow: 0 2px 4px 0 #cfc7e7;
	}
}

@include media('md'){
	.filter-loader{
		overflow: hidden;
		height: 584px;
		padding: 24px 12px;
	}

	.filter-loader__bottom{
		display: none;
	}

	.filter-loader__top{
		display: flex;
		flex-wrap: wrap;
		margin-left: 0;
		margin-top: -20px;

		& > div{
			width: 100%;
			margin-left: 0;
			margin-top: 20px;
			height: 48px;
		}
	}
}
</style>
